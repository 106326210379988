import { useState } from "react";

import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, Modal } from "antd";
import PropTypes from "prop-types";

import useUserDelete from "../../../../api/hooks/useUserDelete";
import CustomTable from "../../../CustomTable/CustomTable";
import "../scss/UsersListTable.scss";
import UnsavedChangesWarning from "../../../Shared/UnsavedChangesWarning";
import EditUser from "../EditUser";
import useHandleDeleteUser from "../hooks/useHandleDeleteUser";

const propertyTypes = {
  data: PropTypes.array,
  status: PropTypes.string,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  onChange: PropTypes.func,
  refetchUsersForManagement: PropTypes.func,
  refetchUsersLicensesCount: PropTypes.func,
};

const defaultPropertyTypes = {
  data: [],
  status: "loading",
  pageSize: 3,
  totalCount: 0,
  onChange: () => {},
  refetchUsersForManagement: () => {},
  refetchUsersLicensesCount: () => {},
};

function UsersListTable({
  data,
  status,
  pageSize,
  totalCount,
  onChange,
  refetchUsersForManagement,
  refetchUsersLicensesCount,
}) {
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [isAlertUnsavedChangesOpen, setAlertUnsavedChangesOpen] =
    useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [editFormReference, setFormReference] = useState();
  const [userId, setUserId] = useState(0);
  const useUserDeleteMutation = useUserDelete();

  const renderTitle = (className, id, value) => {
    return <span id={id}>{value}</span>;
  };

  const renderText = (value) => {
    return (
      <div>
        <span className="text-white text-opacity-80 font-light">{value}</span>
      </div>
    );
  };

  const handleOnCancelEditModal = () => {
    if (isFormDirty) {
      setAlertUnsavedChangesOpen(true);
    } else {
      setIsEditUserModalOpen(false);
      editFormReference.resetFields();
      refetchUsersForManagement();
      refetchUsersLicensesCount();
    }
  };

  const HandleOnDeleteOk = async (user) => {
    await useHandleDeleteUser(useUserDeleteMutation, user);
    refetchUsersForManagement();
    refetchUsersLicensesCount();
  };

  const handleDeleteClick = (object) => {
    Modal.confirm({
      width: 500,
      title: (
        <div className="text-center uppercase flex flex-col justify-center items-center">
          <ExclamationCircleOutlined
            style={{ fontSize: 36, color: "#ed1c24", marginBottom: "1rem" }}
          />
          are you sure?
        </div>
      ),
      content: (
        <div className="text-center uppercase">
          <p className="my-1">
            Do you want to delete user &quot;{object.Username}&quot;?
          </p>
          <p className="my-1">This process cannot be undone.</p>
        </div>
      ),
      className: "alert-delete-confirm",
      icon: null,
      onOk() {
        HandleOnDeleteOk(object);
      },
      onCancel() {
        // Do Nothing
      },
      okButtonProps: {
        danger: true,
        className: "btn-leave",
      },
      okText: "Delete",
      cancelButtonProps: { className: "btn-cancel" },
    });
  };

  const tableColumns = [
    {
      title: renderTitle(null, "username-col", "User Name"),
      key: "username",
      render: (object) => {
        return renderText(object?.Username);
      },
      width: 450,
    },
    {
      title: renderTitle(null, null, "Email"),
      key: "user_email",
      render: (object) => {
        return renderText(object?.Email);
      },
      width: 200,
    },
    {
      title: renderTitle(null, null, "Mobile"),
      key: "user_phone",
      render: (object) => {
        return renderText(object?.PhoneNumber);
      },
      width: 150,
    },
    {
      title: renderTitle(null, null, "Permission"),
      key: "user_permission",
      render: (object) => {
        return renderText(object?.Permission);
      },
      width: 150,
    },
    {
      title: renderTitle(null, null, "License Key"),
      key: "user_licenseKey",
      render: (object) => {
        return renderText(object?.LicenseKey);
      },
      width: 250,
    },
    {
      title: renderTitle(null, null, "Purchase Key"),
      key: "user_purchaseKey",
      render: (object) => {
        return renderText(object?.PurchaseKey);
      },
      width: 250,
    },
    {
      title: "Actions",
      key: "actions",
      render: (object) => {
        return (
          <>
            <Button
              className="mr-1"
              key={`edit-${object.UserId}`}
              size="small"
              onClick={() => {
                setIsEditUserModalOpen(true);
                setUserId(object.UserId);
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              danger
              key={`delete-${object.UserId}`}
              className="ml-1"
              size="small"
              onClick={() => {
                handleDeleteClick(object);
              }}
            >
              <DeleteOutlined />
            </Button>
          </>
        );
      },
      width: 100,
    },
  ];

  return (
    <>
      <CustomTable
        className={`${!pageSize ? "opacity-0" : "opacity-100"}`}
        data={data}
        status={status}
        autoRows
        noPagination={!(totalCount > pageSize) || status === "loading"}
        tableColumns={tableColumns}
        totalCount={totalCount}
        onChange={onChange}
      />
      <Modal
        className="create-new-user-modal"
        maskClosable={false}
        footer={null}
        open={isEditUserModalOpen}
        keyboard={false}
        onCancel={handleOnCancelEditModal}
        destroyOnClose
        wrapClassName="custom-create-user-modal-content"
        width={660}
        bodyStyle={{
          height: 650,
          overflowY: "auto",
          marginTop: "1.5rem",
        }}
      >
        <EditUser
          setIsFormDirty={setIsFormDirty}
          setFormReference={setFormReference}
          userId={userId}
        />
      </Modal>
      <UnsavedChangesWarning
        onSaveClick={() => {
          setAlertUnsavedChangesOpen(false);
          editFormReference.submit();
        }}
        onDontSaveClick={() => {
          setAlertUnsavedChangesOpen(false);
          setIsEditUserModalOpen(false);
          setIsFormDirty(false);
          editFormReference.resetFields();
          refetchUsersForManagement();
          refetchUsersLicensesCount();
        }}
        onCancelClick={() => {
          setAlertUnsavedChangesOpen(false);
        }}
        isAlertUnsavedChangesOpen={isAlertUnsavedChangesOpen}
      />
    </>
  );
}

UsersListTable.propTypes = propertyTypes;
UsersListTable.defaultProps = defaultPropertyTypes;

export default UsersListTable;
