import ExclamationMarkIcon from "../../../../assets/icons/ExclamationMarkIcon";
import WaterDropIcon from "../../../../assets/icons/WaterDropIcon";
import useWindowSize from "../../../../hooks/useWindowSize";
import MenuTile from "../../../Shared/MenuTile";

function Menu() {
  const window = useWindowSize();

  return (
    <>
      {window?.width > 768 && (
        <h1 className="page-title mb-0 mt-1 text-center sm:text-left !text-triple-blue">
          Reports
        </h1>
      )}

      {window?.width <= 768 && (
        <h1 className="page-title-mobile mb-0 mt-1 text-center sm:text-left uppercase !text-triple-blue">
          Reports
        </h1>
      )}
      <div className="mt-2 flex md:flex-row flex-col md:items-start items-center gap-5">
        <MenuTile
          title="Consumption Report"
          icon={
            <WaterDropIcon
              className="w-12 h-12 xxxl:w-20 xxxl:h-20 align-sub"
              strokeColor="#757782"
            />
          }
          navigateTo="/preferences/reports/consumption"
        />
        <MenuTile
          title="Status Report"
          icon={<ExclamationMarkIcon />}
          navigateTo="/preferences/reports/status"
        />
      </div>
    </>
  );
}

export default Menu;
