import { useEffect, useState, useMemo } from "react";

import { Table, Select, Row } from "antd";
import PropTypes from "prop-types";
import "./Table.scss";

const { Option } = Select;

const propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  enableStatusFilter: PropTypes.bool,
};

const defaultProps = {
  data: [],
  loading: false,
  enableStatusFilter: false,
};

function CustomTable({ data, loading, enableStatusFilter }) {
  const [filteredData, setFilteredData] = useState(data);
  const [statusFilterOptions, setStatusFilterOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const headerKeys = useMemo(
    () => (data && data.length > 0 ? Object.keys(data[0]) : []),
    [data],
  );
  const headers = headerKeys.map((h) => ({
    title: h,
    dataIndex: h,
    key: h,
    width: 200,
  }));

  useEffect(() => {
    setFilteredData(data);

    if (enableStatusFilter && headerKeys.includes("Status")) {
      const statusValues = [...new Set(data.map((row) => row.Status))].filter(
        Boolean,
      );
      setStatusFilterOptions(statusValues);
    }
  }, [enableStatusFilter, loading, data, headerKeys]);

  if (!loading) {
    const handleStatusFilterChange = (value) => {
      setSelectedStatus(value);

      if (value) {
        setFilteredData(data.filter((item) => item.Status === value));
      } else {
        setFilteredData(data); // Reset to all data if no filter is applied
      }
    };

    const filteredHeaders = headers.map((col) => {
      if (col.dataIndex === "Status" && statusFilterOptions.length > 0) {
        return {
          ...col,
          filters: statusFilterOptions.map((status) => ({
            text: status,
            value: status,
          })),
          onFilter: (value, record) => record.Status === value,
          filterMultiple: false,
        };
      }
      return col;
    });

    return (
      <>
        {enableStatusFilter && (
          <Row className="w-full md:w-1/3 mb-3">
            <Select
              style={{ width: 200 }}
              placeholder="Select Status"
              value={selectedStatus}
              className="wcr-report-filter-selectors"
              onChange={handleStatusFilterChange}
              allowClear
            >
              {statusFilterOptions.map((status, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Option key={index} value={status}>
                  {status}
                </Option>
              ))}
            </Select>
          </Row>
        )}

        <Table
          columns={filteredHeaders}
          dataSource={filteredData}
          scroll={{
            x: 500,
          }}
          pagination={false}
          className="reports-table"
          locale={{ emptyText: "Loading data" }}
        />
      </>
    );
  }

  return (
    <Table
      locale={{ emptyText: "No data" }}
      columns={[]}
      dataSource={[]}
      scroll={{
        x: 500,
      }}
      className="reports-table"
    />
  );
}

CustomTable.propTypes = propTypes;
CustomTable.defaultProps = defaultProps;

export default CustomTable;
