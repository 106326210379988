import {
  FlowManagementIcon,
  ScheduleManagementIcon,
} from "../../assets/icons/preferences-tiles-icons";
import useWindowSize from "../../hooks/useWindowSize";
import MenuTile from "../Shared/MenuTile";

function SettingsMenuPage() {
  const window = useWindowSize();
  return (
    <>
      {window?.width > 768 && (
        <h1 className="page-title text-center sm:text-left !text-triple-blue">
          Settings
        </h1>
      )}

      {window?.width <= 768 && (
        <h1 className="page-title-mobile text-center sm:text-left uppercase !text-triple-blue">
          Settings
        </h1>
      )}

      <div className="mt-2 flex md:flex-row flex-col md:items-start items-center gap-5">
        <MenuTile
          title="Flow Configuration"
          icon={<FlowManagementIcon />}
          navigateTo="flow-configuration"
        />
        <MenuTile
          title="Schedule"
          icon={<ScheduleManagementIcon />}
          navigateTo="schedule"
        />
      </div>
    </>
  );
}

export default SettingsMenuPage;
