import { useEffect, useRef, useState } from "react";

import {
  FrownOutlined,
  LockOutlined,
  PlusOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, notification, Radio, Select } from "antd";
import PropTypes from "prop-types";

import usePurchaseOptions from "../../../api/hooks/usePurchaseOptions";
import useUsersManagementCreateUser from "../../../api/hooks/useUsersManagementCreateUser";
import useUsersManagementGetDedicatedLicenses from "../../../api/hooks/useUsersManagementGetDedicatedLicenses";
import RegularExpressions from "../../../constants/RegExpressions";
import { groupBy } from "../../../helpers/jsLinq";
import "./scss/CreateUser.scss";
import UsersPermissionsList from "./UsersPermissionsList/UsersPermissionsList";

function CreateUser({
  setIsFormDirty,
  setFormReference,
  setIsCreateUserModalOpen,
  refetchUsersForManagement,
  refetchUsersLicensesCount,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [disableSaveChanges, setDisableSaveChanges] = useState(true);
  const [licensesData, setLicensesData] = useState();
  const [licenseValue, setLicenseValue] = useState();
  const { mutate: usersManagementCreateUser } = useUsersManagementCreateUser();

  const [createUserForm] = Form.useForm();
  const initialFormValues = useRef({});
  initialFormValues.current = {
    FirstName: "",
    LastName: "",
    Email: "",
    PhoneNumber: "",
    LicenseValue: "",
    PurchaseKey: "",
  };

  const { data: dedicatedLicenses } = useUsersManagementGetDedicatedLicenses({
    enabled: true,
    refetchOnWindowsFocus: true,
    cacheTime: 0,
  });

  const purchaseOptions = [
    {
      value: "",
      label: "",
    },
  ];

  // Hooks
  const { data: purchasesResult } = usePurchaseOptions();
  // Configure the purchase plan select dropdown with values
  if (purchasesResult) {
    purchasesResult.forEach((p) =>
      purchaseOptions.push({
        value: p.UniqueId,
        label: `${p.LicensePlan.Name}: ${p.UniqueId}`,
      }),
    );
  }

  useEffect(() => {
    setFormReference(createUserForm);
  }, [createUserForm, setFormReference]);

  useEffect(() => {
    if (dedicatedLicenses) {
      const groupedData = groupBy(dedicatedLicenses.Result, "UserLevelName");
      // Removes Admin from the grouped result data.
      const { Admin, ...newGroupedData } = groupedData;
      setLicensesData(newGroupedData);
    }
  }, [dedicatedLicenses]);

  const handleFieldsChange = (changedFields) => {
    const createUserFormValues = createUserForm.getFieldsValue();
    const fieldName = changedFields[0]?.name[0];
    const fieldValue = changedFields[0]?.value;

    if (fieldName === "PhoneNumber") {
      if (fieldValue === "") {
        setDisableSaveChanges(true);
        createUserForm.setFields([
          {
            name: "PhoneNumber",
            errors: ["Phone Number field is required."],
          },
        ]);
      } else {
        const isValidInput = RegularExpressions.NumbersOnly.test(fieldValue);
        if (isValidInput) {
          createUserForm.setFields([{ name: "PhoneNumber", errors: [] }]);
        } else {
          setDisableSaveChanges(true);
          createUserForm.setFields([
            {
              name: "PhoneNumber",
              errors: ["Phone Number should contain only digits."],
            },
          ]);
        }
      }
    }

    if (
      createUserFormValues?.FirstName !== initialFormValues.current.FirstName ||
      createUserFormValues?.LastName !== initialFormValues.current.LastName ||
      createUserFormValues?.PhoneNumber !==
        initialFormValues.current.PhoneNumber ||
      createUserFormValues?.Email !== initialFormValues.current.Email
    ) {
      setDisableSaveChanges(false);
    }

    if (
      createUserFormValues?.FirstName === initialFormValues.current.FirstName &&
      createUserFormValues?.LastName === initialFormValues.current.LastName &&
      createUserFormValues?.PhoneNumber ===
        initialFormValues.current.PhoneNumber &&
      createUserFormValues?.Email === initialFormValues.current.Email
    ) {
      setDisableSaveChanges(true);
    }
  };

  const handleOnValuesChange = (changedValues) => {
    const isFormDirty = Object.keys(changedValues).length > 0;
    setIsFormDirty(isFormDirty);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const createUserFormValues = createUserForm.getFieldsValue();

    // Remove extra whitespaces from a string and ensure there is only one space between.
    const nameParts = createUserFormValues.FullName.replace(/\s+/g, " ")
      .trim()
      .split(" ");
    const firstName = nameParts[0];
    // The rest of the array elements are joined back together to form the last name.
    const lastName = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";
    const request = {
      FirstName: firstName,
      LastName: lastName,
      ...createUserFormValues,
    };

    usersManagementCreateUser(request, {
      onSuccess: () => {
        setIsLoading(false);
        notification.success({
          message: (
            <span className="uppercase tracking-widest">User created</span>
          ),
          duration: 5,
          placement: "topRight",
        });
        setIsFormDirty(false);
        setIsCreateUserModalOpen(false);
        createUserForm.resetFields();
        refetchUsersForManagement();
        refetchUsersLicensesCount();
      },
      onError: (error) => {
        setIsLoading(false);
        notification.error({
          message: (
            <span className="uppercase tracking-widest">
              Something went wrong!
            </span>
          ),
          description:
            error.response &&
            error.response.status !== 500 &&
            error.response.data
              ? error.response.data
              : undefined,
          duration: 0,
          placement: "topRight",
          icon: <FrownOutlined className="text-triple-red" />,
        });
      },
    });
  };

  return (
    <div className="content-wrapper overflow-y-auto create-user-form mr-3">
      <Form
        form={createUserForm}
        name="create-user-form"
        layout="horizontal"
        className="w-full"
        onFinish={handleSubmit}
        onFieldsChange={handleFieldsChange}
        onValuesChange={handleOnValuesChange}
        initialValues={initialFormValues.current}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        labelAlign="left"
      >
        <div className="create-new-user-title">
          <span className="text-center mr-1 uppercase tracking-wider">
            create new user
          </span>
          <PlusOutlined className="text-lg" />
        </div>
        <div className="border border-solid rounded p-1 mt-6 border-triple-grey">
          <Form.Item
            className="mb-0"
            label="Full Name"
            name="FullName"
            rules={[
              { required: true, message: "Full Name field is required." },
            ]}
          >
            <Input size="small" maxLength={100} />
          </Form.Item>
        </div>
        <div className="border border-solid rounded p-1 mt-4 border-triple-grey">
          <Form.Item
            className="mb-0"
            label="Mobile"
            name="PhoneNumber"
            rules={[
              {
                required: true,
                message: "Phone Number field is required.",
              },
            ]}
          >
            <Input size="small" maxLength={15} />
          </Form.Item>
        </div>
        <div className="border border-solid rounded p-1 mt-4 border-triple-grey">
          <Form.Item
            className="mb-0"
            label="Email"
            name="Email"
            rules={[
              { required: true, message: "Email field is required." },
              { type: "email", message: "Invalid email address." },
            ]}
          >
            <Input size="small" maxLength={100} />
          </Form.Item>
        </div>
        <div className="border border-solid rounded p-1 mt-4 border-triple-grey">
          <Form.Item className="mb-0" label="Property">
            <Select
              mode="multiple"
              disabled
              className="w-full"
              placeholder=""
              size="small"
            />
          </Form.Item>
        </div>
        <div className="border border-solid rounded p-1 mt-4 border-triple-grey">
          <Form.Item
            className="mb-0"
            label="Purchase Plan"
            name="PurchaseKey"
            rules={[
              { required: true, message: "Purchase Plan field is required." },
            ]}
          >
            <Select
              className="w-full"
              placeholder=""
              size="small"
              options={purchaseOptions}
            />
          </Form.Item>
        </div>
        <div className="border border-solid border-triple-blue rounded p-1 mt-4">
          <Form.Item
            className="mb-0"
            label="License"
            name="LicenseValue"
            rules={[
              {
                required: true,
                message: "License field is required.",
              },
            ]}
          >
            <Radio.Group
              buttonStyle="solid"
              className="flex w-full cursor-pointer"
              size="small"
              onChange={(event) => {
                setLicenseValue({
                  value: event?.target?.value,
                  checked: event?.target?.checked,
                });
              }}
            >
              <Radio.Button
                value="Operator"
                className="text-center flex-1 mx-1 rounded license-radio-btn"
                disabled={
                  licensesData?.Operator?.length === 0 ||
                  licensesData?.Operator === undefined
                }
              >
                <span className="font-light text-triple-white uppercase tracking-widest">
                  Operator
                </span>
              </Radio.Button>
              {licenseValue?.value === "Operator" ? (
                <UnlockOutlined style={{ fontSize: 20, color: "#06A5D3" }} />
              ) : (
                <LockOutlined style={{ fontSize: 20 }} />
              )}
              <Radio.Button
                value="User"
                className="text-center flex-1 mx-1 rounded license-radio-btn"
                disabled={
                  licensesData?.User?.length === 0 ||
                  licensesData?.User === undefined
                }
              >
                <span className="font-light text-triple-white uppercase tracking-widest">
                  User
                </span>
              </Radio.Button>
              {licenseValue?.value === "User" ? (
                <UnlockOutlined style={{ fontSize: 20, color: "#06A5D3" }} />
              ) : (
                <LockOutlined style={{ fontSize: 20 }} />
              )}
            </Radio.Group>
          </Form.Item>
        </div>

        <UsersPermissionsList
          formRef={createUserForm}
          licensesData={licensesData}
        />

        <Button
          type="primary"
          htmlType="submit"
          size="medium"
          className="custom-create-user-save-btn"
          disabled={disableSaveChanges}
          loading={isLoading}
        >
          Save
        </Button>
      </Form>
    </div>
  );
}

CreateUser.propTypes = {
  setIsFormDirty: PropTypes.func,
  setFormReference: PropTypes.func,
  setIsCreateUserModalOpen: PropTypes.func,
  refetchUsersForManagement: PropTypes.func,
  refetchUsersLicensesCount: PropTypes.func,
};

CreateUser.defaultProps = {
  setIsFormDirty: () => {},
  setFormReference: () => {},
  setIsCreateUserModalOpen: () => {},
  refetchUsersForManagement: () => {},
  refetchUsersLicensesCount: () => {},
};

export default CreateUser;
