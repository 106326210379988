const predefinedStructure = {
  Dashboard: ["Dashboard Default View", "View all Events (Redirect)"],
  "Property Overview": [
    "Property Overview Default View",
    "Send Commands",
    "Property Overview Virtual View",
  ],
  Statistics: ["Yearly Graph", "Monthly Graph", "Daily Graph"],
  Reports: [
    "Generate Report",
    "Water Consumption - Table View",
    "Water Consumption - Graph View",
    "Systems Real Time Status Report",
  ],
  Events: ["Events Default View", "Export Events"],
  Settings: [
    "Flow Configuration Default View",
    "Schedule Default View",
    "Create New Schedule",
    "Edit Schedule",
    "Create New FC",
    "Edit FC",
  ],
};

const userStaticData = {
  UserLevelName: "User",
  UserLevelFeatures: [
    {
      FeatureId: 2,
      FeatureName: "Dashboard Default View",
      UserLevelId: 3,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Dashboard",
    },
    {
      FeatureId: 30,
      FeatureName: "View all Events (Redirect)",
      UserLevelId: 3,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Dashboard",
    },
    {
      FeatureId: 3,
      FeatureName: "Property Overview Default View",
      UserLevelId: 3,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Property Overview",
    },
    {
      FeatureId: 36,
      FeatureName: "Property Overview Virtual View",
      UserLevelId: 3,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Property Overview",
    },
    {
      FeatureId: 6,
      FeatureName: "Events Default View",
      UserLevelId: 3,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Events",
    },
  ],
};

const operatorStaticData = {
  UserLevelId: 4,
  UserLevelName: "Operator",
  UserLevelFeatures: [
    {
      FeatureId: 2,
      FeatureName: "Dashboard Default View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Dashboard",
    },
    {
      FeatureId: 30,
      FeatureName: "View all Events (Redirect)",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Dashboard",
    },
    {
      FeatureId: 3,
      FeatureName: "Property Overview Default View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Property Overview",
    },
    {
      FeatureId: 29,
      FeatureName: "Send Commands",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Property Overview",
    },
    {
      FeatureId: 36,
      FeatureName: "Property Overview Virtual View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Property Overview",
    },
    {
      FeatureId: 4,
      FeatureName: "Yearly Graph",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Statistics",
    },
    {
      FeatureId: 19,
      FeatureName: "Monthly Graph",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Statistics",
    },
    {
      FeatureId: 38,
      FeatureName: "Water Consumption - Table View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Reports",
    },
    {
      FeatureId: 39,
      FeatureName: "Water Consumption - Graph View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Reports",
    },
    {
      FeatureId: 40,
      FeatureName: "Systems Real Time Status Report",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Reports",
    },
    {
      FeatureId: 6,
      FeatureName: "Events Default View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Events",
    },
    {
      FeatureId: 7,
      FeatureName: "Flow Configuration Default View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Settings",
    },
    {
      FeatureId: 26,
      FeatureName: "Create New FC",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Settings",
    },
    {
      FeatureId: 27,
      FeatureName: "Edit FC",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Settings",
    },
    {
      FeatureId: 8,
      FeatureName: "Schedule Default View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Settings",
    },

    {
      FeatureId: 24,
      FeatureName: "Create New Schedule",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Settings",
    },
    {
      FeatureId: 25,
      FeatureName: "Edit Schedule",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Settings",
    },
  ],
};

export { predefinedStructure, userStaticData, operatorStaticData };
