import { Button } from "antd";
import PropTypes from "prop-types";
import "./CmsFooter.scss";

function CmsFooter({ className }) {
  const currentYear = new Date().getFullYear();
  const currentAppVersion = process.env.REACT_APP_VERSION;
  const wasensLink = (
    <Button
      type="link"
      href="https://wasens.net/en/"
      target="_blank"
      rel="noopener noreferrer"
      size="small"
      style={{ padding: 0 }}
    >
      <span className="text-triple-blue">WASENS</span>
    </Button>
  );
  return (
    <div className={className}>
      <span className="mr-1">
        Copyright &copy; {currentYear} {wasensLink}
      </span>
      <span className="mr-4">
        All rights reserved.Version {currentAppVersion}
      </span>
    </div>
  );
}

CmsFooter.defaultProps = {
  className: "wasens-footer",
};

CmsFooter.propTypes = {
  className: PropTypes.string,
};

export default CmsFooter;
