import WaterDropIcon from "../../../../assets/icons/WaterDropIcon";
import useWindowSize from "../../../../hooks/useWindowSize";
import MenuTile from "../../../Shared/MenuTile";

function ReportsMenuPage() {
  const window = useWindowSize();

  return (
    <>
      {window?.width > 768 && (
        <h1 className="page-title mb-0 mt-1 text-center sm:text-left !text-triple-blue">
          Consumption Reports
        </h1>
      )}

      {window?.width <= 768 && (
        <h1 className="page-title-mobile mt-1 text-center sm:text-left uppercase !text-triple-blue">
          Consumption Reports
        </h1>
      )}

      <div className="flex md:flex-row mt-2 flex-col md:items-start items-center gap-5">
        <MenuTile
          title="Consumption Report"
          icon={
            <WaterDropIcon
              className="w-12 h-12 xxxl:w-20 xxxl:h-20 align-sub"
              strokeColor="#757782"
            />
          }
          navigateTo="/reports/consumption/water"
        />
      </div>
    </>
  );
}

export default ReportsMenuPage;
